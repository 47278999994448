<template>
  <div>
      <b-row>
          <b-col class="mb-5">
            <b-list-group>
                <b-list-group-item variant="secondary">Languages</b-list-group-item>
                <b-list-group-item>Node</b-list-group-item>
                <b-list-group-item>Javascript (ES6) / Typescript</b-list-group-item>
                <b-list-group-item>PHP</b-list-group-item>
                <b-list-group-item>Python</b-list-group-item>
                <b-list-group-item>HTML5</b-list-group-item>
                <b-list-group-item>Rust</b-list-group-item>
            </b-list-group>
          </b-col>

          <b-col class="mb-5">
            <b-list-group>
                <b-list-group-item variant="secondary">Frameworks</b-list-group-item>
                <b-list-group-item>React</b-list-group-item>
                <b-list-group-item>Vue</b-list-group-item>
                <b-list-group-item>web3.js</b-list-group-item>
                <b-list-group-item>Laravel</b-list-group-item>
                <b-list-group-item>Bootstrap</b-list-group-item>
                <b-list-group-item>Material</b-list-group-item>
            </b-list-group>
          </b-col>
          
          <b-col class="mb-5">
            <b-list-group>
                <b-list-group-item variant="secondary">Databases</b-list-group-item>                
                <b-list-group-item>Mongo</b-list-group-item>
                <b-list-group-item>MySQL</b-list-group-item>
                <b-list-group-item>Redis</b-list-group-item>
            </b-list-group>
          </b-col>

          <b-col>
            <b-list-group>
                <b-list-group-item variant="secondary">Technologies</b-list-group-item>
                <b-list-group-item>Docker</b-list-group-item>
                <b-list-group-item>Kubernetes</b-list-group-item>
                <b-list-group-item>AWS</b-list-group-item>
                <b-list-group-item>REST APIs</b-list-group-item>
                <b-list-group-item>Microservices</b-list-group-item>
                <b-list-group-item>git</b-list-group-item>
            </b-list-group>
          </b-col>
      </b-row>
  </div>
</template>

<script>
export default {
  name: 'Skills',
}
</script>

<style>
</style>
