<template>
  <div>
        <b-row>
          <b-col sm="6">
            <b-card
        header="Coin Payments"
        title="Lead Developer"
        bg-variant="light"
        class="mb-3">
          <b-card-text>
          Aug 2022 - Present
          </b-card-text>

          <b-list-group>
            <b-list-group-item>
              The go-to developer for app enhancements, data reporting, and bug fixes.
            </b-list-group-item>
            <b-list-group-item>
              Currently migrating legacy PHP platform to modernized C# platform using the strangler pattern.
            </b-list-group-item>
            <b-list-group-item>
              Responsible for maintaining app servers and RPC nodes for 1,500+ supported cryptos.            
            </b-list-group-item>
          </b-list-group>
        </b-card>
        </b-col>

          <b-col sm="6">
            <b-card
        header="Remax"
        title="Software Engineer"
        bg-variant="light"
        class="mb-3">
          <b-card-text>
          2021 - 2022
          </b-card-text>

          <b-list-group>
            <b-list-group-item>
              Top-performing engineer on Campaigns team. Looked for the worst bugs/issues and dove in head-first.
            </b-list-group-item>
            <b-list-group-item>
              Hired to work on frontend Vue/Tailwinds stack. Quickly progressed to full-stack which includes Laravel, MySQL and Elastic Search.
            </b-list-group-item>
            <b-list-group-item>
              Continually pushed team to put out clean, professional code with good test coverage.
            </b-list-group-item>
          </b-list-group>
        </b-card>
        </b-col>
        </b-row>

        <b-row>
        <b-col>
            <b-card
        header="Records By Mail"
        title="Full Stack Developer"
        bg-variant="light"
        class="mb-3">
          <b-card-text>
          2006 - 2016, 2019 - Present
          </b-card-text>

          <b-list-group>
            <b-list-group-item>
              Built 200,000+ unit inventory and order management system using Node, Mongo, Redis, Auth0 and Vue. Deployed APIs using microservice architecture onto AWS EC2 instances. Wrote unit tests (Mocha, Chai) for application logic.
            </b-list-group-item>
            <b-list-group-item>
            Sole developer and designer for entire responsive e-commerce website using Vue, HTML5, and CSS3.
            </b-list-group-item>
            <b-list-group-item>
              Numerous marketplace integrations via REST APIs including eBay, Amazon and Discogs. Experience with OAuth, CloudWatch for monitoring and alerting.
            </b-list-group-item>
            <b-list-group-item>
              Numerous marketplace integrations via REST APIs including eBay, Amazon and Discogs. Experience with OAuth, CloudWatch for monitoring and alerting.
            </b-list-group-item>
            <b-list-group-item>
              Restructured staff for efficiency gains and positive environment changes.
            </b-list-group-item>
            <b-list-group-item>
              Ran direct mail marketing campaign to purchase profitable record collections around the country.
            </b-list-group-item>
          </b-list-group>
        </b-card>
          </b-col>

          <b-col sm="6">
        <b-card
        header="Compli / KPA"
        title="Lead Software Engineer"
        bg-variant="light"
        class="mb-3">
          <b-card-text>
          Aug 2016 - Feb 2019
          </b-card-text>

          <b-list-group>
            <b-list-group-item>
              Started as front-end engineer using responsive design practices. Implemented revenue-generating features with Vue/SASS/HTML5 that consumed REST APIs.
            </b-list-group-item>
            <b-list-group-item>
              Developed into a full-stack engineer building and consuming REST APIs using a variety of technologies and frameworks, including: Node, Express, Laravel, Mongo, and MySQL.
            </b-list-group-item>
            <b-list-group-item>
              Promoted to team lead from a combination of leadership and productivity.
            </b-list-group-item>
            <b-list-group-item>
              Used Docker, Kubernetes, and AWS to create and deploy microservices.
            </b-list-group-item>
            <b-list-group-item>
              Led by example in using test-driven development as the sole method for building microservices and fixing bugs.
            </b-list-group-item>
            <b-list-group-item>
            Participated in interviewing, vetting and mentoring numerous employees and interns.
            </b-list-group-item>
          </b-list-group>
        </b-card>
          </b-col>
        </b-row>
        
  </div>
</template>

<script>
export default {
  name: 'Experience',
}
</script>

<style>
</style>
